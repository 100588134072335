import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { sendContactRequestAction } from "../../../redux/actions/send-contact.actions";
import { sendFranchiseRequestAction } from "../../../redux/actions/send-franchise.actions";
import { eventFormSubmission } from "../../../services/analytics/ga4CustomEvents";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import { useCookies } from "react-cookie";
import useFetch from "../../../../ClassyKitchen/hooks/useFetch";
const EMAIL_URL = "/api/contact/email";
import TagManager from "react-gtm-module";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId }) => {
  const { loading, failed } = useSelector(
    ({ sentContactData }) => sentContactData,
  );
  const dispatch = useDispatch();
  const { submit, responseData } = useFetch();
  const [cookie, setCookie] = useCookies(["converted"]);
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);

  const whereToDispatch = {
    "franchise": sendFranchiseRequestAction,
    "contact": sendContactRequestAction,
  };

  const action = whereToDispatch[dispatchDestination];

  const handleSubmit = (values) => {
    let url = typeof window !== "undefined" ? window.location.href : "null";
    setGoogleAdwordsInfo(values);
    TagManager.dataLayer({
      dataLayer: {
        crmOrClassic: "classic",
      },
    });
    dispatch(
      action({
        ...values,
        queries: getQueryFromStorage(),
        user_id: window.uniqueVisitorId,
        confidenceScore: window.confidenceScore,
        fingerprint: window.uniqueVisitorId
      }),
    );
    submit(EMAIL_URL, "POST", values);
    eventFormSubmission(url, "lead", {
      email: values.email,
      phone: values.phone,
      crmOrClassic: "classic",
    });
    setCookie("converted", "step_1", {
      path: "/",
      maxAge: 86400 * 7 // 7 days
    });
    setTimeout(() => {
      form.resetFields();
      setShowModal(true);
    }, 300);
  };

  if (variant === "large")
    return (
      <FormConsultationLarge
        handleSubmit={handleSubmit}
        loading={loading}
        form={form}
        theme={theme}
        referralCode={referralCode}
        failed={failed}
        showModal={showModal}
        setShowModal={setShowModal}
        submitButton={submitButton}
        dispatchDestination={dispatchDestination}
        formId={formId}
      />
    );

  return (
    <FormConsultationSmall
      handleSubmit={handleSubmit}
      loading={loading}
      form={form}
      theme={theme}
      referralCode={referralCode}
      failed={failed}
      showModal={showModal}
      setShowModal={setShowModal}
      submitButton={submitButton}
      dispatchDestination={dispatchDestination}
      formId={formId}
    />
  );
};

FormConsultation.propTypes = {
  theme: PropTypes.object,
  variant: PropTypes.string,
  referralCode: PropTypes.string,
  submitButton: PropTypes.string,
  dispatchDestination: PropTypes.string,
  id: PropTypes.string,
};

export default withTheme(FormConsultation);
